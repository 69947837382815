'use client'

// <div style="padding:125% 0 0 0;position:relative;">
// <iframe src="https://player.vimeo.com/video/937596770?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"
// allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="chimi_instagram">
// </iframe>
// </div>
// <script src="https://player.vimeo.com/api/player.js"></script>

// <iframe src="https://player.vimeo.com/video/{video_id}?h={hash_parameter}" width="{video_width}" height="{video_height}"
// frameborder="0" title="{video_title}" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { VideoQuery } from '@/data/model'

export interface VimeoPlayerProps {
  video: VideoQuery
  controls?: boolean
  className?: string
}

// https://developer.vimeo.com/player/sdk/embed
export function VimeoPlayer(props: VimeoPlayerProps): React.ReactElement {
  const { video, controls } = props

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
  })

  const [showThumb, setShowThumb] = useState(true)
  const aspectRatio = video.width / video.height
  const thumbUrl = getThumbnailUrl(video)

  const qs = `badge=0&autopause=0&autoplay=1&muted=1&background=${controls ? '0' : '1'}&controls=${controls ? '1' : '0'}&progress_bar=${controls ? '1' : '0'}&responsive=1&vimeo_logo=0&player_id=0&app_id=58479`

  function hideThumb() {
    setTimeout(() => {
      setShowThumb(false)
    }, 2000)
  }

  return (
    <>
      <div
        ref={ref}
        style={{
          aspectRatio,
          position: 'relative',
        }}
        className={props.className}
      >
        {showThumb && (
          <img
            src={thumbUrl}
            loading="lazy"
            alt={video.title}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              aspectRatio,
            }}
          />
        )}

        {inView && (
          <iframe
            src={`https://player.vimeo.com/video/${video.providerUid}?${qs}`}
            frameBorder={0}
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              aspectRatio,
              border: 0,
              zIndex: controls ? 3 : -1,
            }}
            title={video.title}
            onLoad={hideThumb}
          />
        )}
      </div>
      {/* <script src="https://player.vimeo.com/api/player.js" />*/}
    </>
  )
}

function getThumbnailUrl(video: VideoQuery): string {
  // https://i.vimeocdn.com/video/1928668972-2cdaec5708eb491c9866a42eff6a3527a6c58a851f606a948e3134c348abdb22-d_500x1250

  const aspectRatio = video.width / video.height
  const baseWidth = 600
  const w = baseWidth
  const h = w / aspectRatio
  const base = video.thumbnailUrl.split('-d_')[0]
  return `${base}-d_${w}x${h}`
}
