import React, { useCallback, useEffect, useState } from 'react'

/* eslint-disable unicorn/prefer-global-this */

export const isSsr = typeof window === 'undefined'

export const isIntersectionObserverAvailable = !isSsr

export const universalBtoa = (str: string): string =>
  isSsr ? Buffer.from(str.toString(), 'binary').toString('base64') : window.btoa(str)

export function useImageLoad(
  ref: React.RefObject<HTMLImageElement | null>,
  callback: (() => void) | undefined,
) {
  const [loaded, setLoaded] = useState(false)

  function handleLoad() {
    setLoaded(true)
    callback?.()
  }

  // https://stackoverflow.com/q/39777833/266535
  // biome-ignore lint: ok
  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (ref.current.complete && ref.current.naturalWidth) {
      handleLoad()
    }
  }, [ref.current])

  return [loaded, handleLoad] as const
}

export function useMergedRef<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  // biome-ignore lint: ok
  return useCallback((element: T) => {
    for (const ref of refs) {
      if (typeof ref === 'function') ref(element)
      else if (ref && typeof ref === 'object') {
        ;(ref as React.MutableRefObject<T>).current = element
      }
    }
  }, refs)
}

export const absolutePositioning: React.CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  maxWidth: 'none',
  maxHeight: 'none',
}
